<!-- eslint-disable -->
<style>
    .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 30px !important;
      }
  .v-select__slot {
    height: 38px !important;
    min-height: 30px !important;
  }
  .d-n{
    display: none;
  }
  .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
    margin-top: 8px !important;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    height: 36px !important;
  }
</style>
<style lang="scss">
.v-menu__content{
  .v-list-item{
    padding: 2px 10px !important;
    &:nth-child(odd){
      background-color: rgb(128, 128, 128,  0.05);
    }
    .v-list-item__action {
        margin: 6px 0;
    }
  }
}

.c-add-new-laboratory-test{
  .mdi-checkbox-blank-outline::before{
      content: "";
      border: 2px solid #888888;
      width: 20px;
      height: 20px;
      border-radius: 5px;
  }
  .mdi-checkbox-marked::before {
      content: "";
      border: 2px solid #888888;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background-color: #007AFF;
      box-shadow: inset 0px 0px 0px 3px white;
  }
}

.status-switch{
    width: 90%;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    height: 38px;
    .v-input--selection-controls .v-input__slot{
      margin: auto;
    }
}
.select--codetest{
  .v-text-field--outlined .v-label {
    top: 18px;
  }
}
</style>
<template>
    <div class="c-add-new-laboratory-test">
      <v-form
      ref="detalle"
      v-model="valid"
      lazy-validation
      class="container-fluid container-info-basic"
      >
        <div class="" >
          <!-- metodologia ensayo -->
          <v-row>
            <v-col cols="12" class="ml-8 pt-0 c-add-new-laboratory-test__row">
              <v-row>
                <v-col cols="6" >
                  <v-text-field
                    class="c-view-title__select"
                    :rules="rules.metodologia"
                    v-model="form_data.data_ensayo.metodologia"
                    :disabled="form_data.editable"
                    :label="$t('ensayos.metodologia')"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col  cols="6" class="pb-0">
                  <v-text-field
                    v-model="form_data.data_ensayo.cod_ensa"
                    :disabled="form_data.editable"
                    outlined
                    :label="$t('ensayos.cod_ensayo')"
                    class="c-view-title__select select--codetest"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
                
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-select
                  class="c-view-title__select"
                  v-model="form_data.laboratorios"
                  :items="data_ensayo.laboratoriosList"
                  :disabled="form_data.editable"
                  :label="$t('ensayos.labs')"
                  item-text="laboratorio.laboratorio"
                  item-value="laboratorio.id"
                  append-icon="mdi-chevron-down"
                  multiple
                  outlined
                  dense
                >
                  <template v-slot:item="{ item }">
                  <div class="o-select__option">
                      {{ getText(item,data_ensayo) }}
                    </div>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ getlang(item.laboratorio.laboratorio) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ laboratorioSelect.length - 1 }} others)
                    </span>
                  </template>
                </v-select>

                </v-col>
                <v-col cols="6" class="pb-0">
                  <div class="status-switch">
                    <div class="d-flex align-items-center justify-content-space-between h-100">
                      <v-col cols="auto" class="text-left py-0">
                        <label class="label-color__gray">{{$t('ensayos.estado')}}</label>
                      </v-col>
                      <v-col cols="auto" class="py-0">
                        <!--   -->
                        <div class="d-flex">
                          <label class="label-color__gray mr-3">{{(!!form_data.data_ensayo.activo) ? $t('ensayos.activo') : $t('ensayos.inactivo') }}</label>
                          <v-switch
                          v-model="form_data.data_ensayo.activo"
                          ></v-switch>
                        </div>
                      </v-col>
                    </div>

                  </div>
                  <!-- <v-select
                    class="c-view-title__select "
                    :items="estatus"
                    v-model="form_data.data_ensayo.activo"
                    :disabled="form_data.editable"
                    :placeholder="$t('ensayos.estado')"
                    append-icon="mdi-chevron-down"
                    item-value="value"
                    item-text="texto"
                    outlined
                    dense
                  ></v-select> -->
                </v-col>
                <!--  -->
              </v-row>

              <v-row v-if="usertxainfo.bitauth_assoc.group_id==1">
                <v-col cols="6">
                   <span hidden>{{(form_data.data_ensayo.costo == 0) ? form_data.data_ensayo.costo = null : form_data.data_ensayo.costo}}</span>
                  <v-text-field
                    class="c-view-title__select"
                    v-model="form_data.data_ensayo.costo"
                    :disabled="form_data.editable"
                    :label="$t('ensayos.costo')"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col cols="12">
              <div class="d-flex align-items-center c-add-new-laboratory-test__step-number">
              <svg class="mr-3" id="Numeral"  width="20" height="20" viewBox="0 0 20 20">
                <g id="Elipse_146" data-name="Elipse 146" fill="#fff" stroke="#057aff" stroke-width="1.5">
                  <circle cx="10" cy="10" r="10" stroke="none"/>
                  <circle cx="10" cy="10" r="9.25" fill="none"/>
                </g>
                <text id="_1" data-name="1" transform="translate(6.171 14.139)" fill="#1a1a1a" font-size="12" font-family="OpenSans-Regular, Open Sans"><tspan x="0" y="0">2</tspan></text>
              </svg>
              <p class="fs16 bold mb-0 c-add-new-laboratory-test__step-title">{{$t('ensayos.Setuptestparameters')}}</p>
            </div>
            <p class="ml-8 c-add-new-laboratory-test__step-subtitle text-left fs14 mb-0">{{$t('ensayos.Setupandaddrelevant')}}</p>
            </v-col>
            <v-col cols="12" class="ml-8 pt-0 c-add-new-laboratory-test__row">
              <v-row>
                <v-col cols="6" class="text-left">
                  <v-select
                  class="c-view-title__select"
                  item-value="id"
                  item-text="nombre2"
                  
                  :rules="rules.tipores"
                  :items="getlangRes(data_ensayo.tipo_resultados)"
                  v-model="form_data.data_ensayo.tipoRes"
                  :label="$t('ensayos.tipo_resultado')"
                  append-icon="mdi-chevron-down"
                  outlined
                  dense
                  required
                ></v-select>
                  <!-- <v-btn 
                    v-if ="form_data.data_ensayo.tipoRes == 3"
                    class="mt-4 c-btn c-btn--white bold"
                    @click="openmodalConfigRecuento"
                    outlined
                    
                    ><v-icon left>
                      mdi-cog-outline
                    </v-icon>
                    {{$t('ensayos.configCountResults')}}
                    
                  </v-btn> -->
                </v-col>
                <v-col cols="6" class="text-left content--btn">
                  <div >
                    <v-btn
                      class="c-btn c-btn--blue bold max-width-300px"
                      outlined
                      @click="openmodalParametros"
                      :disabled="form_data.editable"
                      block
                    >
                      {{$t('ensayos.Setuptestparameters')}}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" >
                  <!-- <span >{{(form_data.data_ensayo.parametrosid.length == 0 ? form_data.data_ensayo.parametrosid = null : form_data.data_ensayo.parametrosid.length)}}</span> -->
                  <v-autocomplete
                    class="c-view-title__select"
                    :items="data_ensayo.parametros"
                    v-model="form_data.data_ensayo.parametrosid"
                    :disabled="form_data.editable"
                    :rules="rules.parametros"
                    :search-input.sync="searchParam"
                    :label="$t('ensayos.param')"
                    append-icon="mdi-chevron-down"
                    item-text="nombre"
                    item-value="cod_pat"
                    multiple="multiple"
                    chips
                    outlined
                    dense
                    persistent-hint
                    >
                  <template v-slot:no-data v-if="searchParam">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('ensayos.txt1') }} "<strong>{{ searchParam }}</strong>"
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                </v-col>
                <!-- Condicion parametros tecnicasList-->
                <v-col cols="6" class="pt-0 text-left content--btn">
                  <v-btn
                  :disabled="disabled()"
                  color="#007AFF"
                  class="bold c-btn white--text max-width-300px"
                  block
                  @click="openmodalTecnicas"
                  >
                    {{ $t('ensayos.tipo_tecnica') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          <!-- RANGOS -->
          <!-- <v-row>
           <v-col class="text-left" cols="12" md="12">
              <div class="d-flex align-items-center c-add-new-laboratory-test__step-number">
                <svg class="mr-3" id="Numeral"  width="20" height="20" viewBox="0 0 20 20">
                  <g id="Elipse_146" data-name="Elipse 146" fill="#fff" stroke="#057aff" stroke-width="1.5">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.25" fill="none"/>
                  </g>
                  <text id="_1" data-name="1" transform="translate(6.171 14.139)" fill="#1a1a1a" font-size="12" font-family="OpenSans-Regular, Open Sans"><tspan x="0" y="0">3</tspan></text>
                </svg>
                <p class="fs16 bold mb-0 c-add-new-laboratory-test__step-title">{{ $t('ensayos.definirEnsayos') }}</p>
              </div>
              <p class="ml-8 c-add-new-laboratory-test__step-subtitle text-left fs14 mb-0">{{ $t('ensayos.rangosLeyenda') }}</p>
            </v-col>
             
              <v-col cols="2" class="ml-8 pt-0">
                  <v-text-field
                    class="c-view-title__select"
                    v-model="form_data.data_ensayo.rango_a"
                    :label="$t('ensayos.rango1')"
                    dense
                    outlined
                  ></v-text-field>
              </v-col>
              <v-col cols="2" class="ml-8 pt-0">
                  <v-text-field
                    class="c-view-title__select"
                    v-model="form_data.data_ensayo.rango_b"
                    :label="$t('ensayos.rango2')"
                    dense
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row> -->

            <!-- </v-row> -->
            <!-- fin indormacion adicional -->
            <!-- SElecciona tus matrices -->
          <v-row>
            <v-col class="text-left" cols="12" md="12">
              <div class="d-flex align-items-center c-add-new-laboratory-test__step-number">
                <svg class="mr-3" id="Numeral"  width="20" height="20" viewBox="0 0 20 20">
                  <g id="Elipse_146" data-name="Elipse 146" fill="#fff" stroke="#057aff" stroke-width="1.5">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9.25" fill="none"/>
                  </g>
                  <text id="_1" data-name="1" transform="translate(6.171 14.139)" fill="#1a1a1a" font-size="12" font-family="OpenSans-Regular, Open Sans"><tspan x="0" y="0">3</tspan></text>
                </svg>
                <p class="fs16 bold mb-0 c-add-new-laboratory-test__step-title"> {{ $t('ensayos.asignacion') }} </p>
              </div>
              <p class="ml-8 c-add-new-laboratory-test__step-subtitle text-left fs14 mb-0">{{ $t('ensayos.select') }}</p>
            </v-col>
          </v-row>
          
          <v-row class="flex-direc-column ml-6">
            <div class="pl-0 pb-0"
              v-for="(item) in form_data.matrices_unidades"
              :key="item.id">
                <v-checkbox
                  v-model="item.activo"
                  :disabled="form_data.editable"
                  @change="activematriz($event,item)"
                  hide-details
                  :label="$t(`matrices.${item.value}`)"
                  class="shrink ml-2 mt-0 mb-0 fs16"
                ></v-checkbox>
              </div>
          </v-row>
        </div>

        <newmix ref="modalmixes"></newmix>
        <parameters ref="modalparametros"></parameters>
        <configRecuento ref="modalConfigRecuento"></configRecuento>
        <tecnicasModal ref="modalTecnicas"></tecnicasModal>
        <condicionParametros ref="modalCondicionParametros"></condicionParametros>
      </v-form>
    </div>
</template>

<script>
/*eslint-disable*/
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';

export default {
  props: {
    setsee: Function,
  },
  components: {
    newmix: () => import('./newMix.vue'),
    parameters: () => import('./modalParametros.vue'),
    configRecuento: () => import('./modalConfigRecuento.vue'),
    tecnicasModal: () => import('./newTecnicas.vue'),
    condicionParametros: () => import('./modalCondicionparam.vue'),
  },
  data: () => ({
    estatus: [{ id: 1, texto: i18n.t('ensayos.activo'), value: 1 }, { id: 2, texto: i18n.t('ensayos.inactivo'), value: 0 }],
    valid: false,
    tipoEnsa: null,
    laboratorioSelect: [],
    
    listaLaboratorio: [
      {
        id: 1,
        laboratorio: 'interno',
      },
      {
        id: 2,
        laboratorio: 'Lab taag mx',
      },
    ],
    // REGLAS
    rules: {
      producto: [
        (v) => !!v || i18n.t('ensayos.rules_txt4'),
      ],
      parametros: [
        (v) => (v.length > 0) || i18n.t('ensayos.rules_txt5'),
      ],
      tipores: [
        (v) => !!v || i18n.t('ensayos.rules_txt6'),
      ],
      tipokit: [
        (v) => !!v || i18n.t('ensayos.rules_txt7'),
      ],
      descripcion: [
        (v) => !!v || i18n.t('ensayos.rules_txt8'),
      ],
      metodologia: [
        (v) => !!v || i18n.t('ensayos.rules_txt9'),
      ],
      empresa: [
        (v) => !!v || i18n.t('ensayos.rules_txt10'),
      ],
      tiempoResultado: [
        (v) => !!v || i18n.t('ensayos.rules_txt11'),
      ],
      tipoTecnica: [
        (v) => !!v || i18n.t('ensayos.rules_txt12'),
      ],
      nombre: [
        (v) => !!v || i18n.t('ensayos.rules_txt13'),
      ],
    },
    activarClas: 0,
    search: null,
    searchProd: null,
    searchParam: null,
    kitsStriplex: [
      {
        id: 1,
        nombre: 'Steriplex Bacterias',
      },
      {
        id: 2,
        nombre: 'Steriplex hongos y levaduras',
      },
    ],
    arraytipoensayo: [
      {
        id: 1,
        nombre: 'Patógeno',
      },
      {
        id: 2,
        nombre: 'Indicador',
      },
      {
        id: 3,
        nombre: 'Deteriorante',
      },
    ],
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo', 'userInfo', 'usertxainfo']),
    listmix() {
      const mixlizt = this.data_ensayo.mix;
      return mixlizt;
    },
    filteredTest() {
      /*
      const listTest = this.data_ensayo.ensayosList.filter((ensayo) => {
        const details = this.form_data.data_ensayo;
        return (ensayo.tipo_ensayo_id === details.tipoEnsa
         && ensayo.comercial === (details.tipoMeto === 1));
      });
      */
      const ensa = this.data_ensayo.ensayosList;
      const details = this.form_data.data_ensayo;
      const listTest = [];
      ensa.forEach((ensayo) => {
        if (ensayo.comercial === true && ensayo.tipo_ensayo_id === details.tipoEnsa) {
          listTest.push(ensayo);
        }
      });
      return listTest;
    },
    listProducts() {
      const pEmpresas = this.data_ensayo.productosEmpresaslist;
      return pEmpresas.filter((prod) => prod.empresa_id === this.form_data.data_ensayo.empresa);
    },
    tecnicasList() {
      const tecnicasL = this.data_ensayo.tecnicas;
      // const dataEnsayoS = this.form_data.data_ensayo;
      return tecnicasL;
      // .filter((tecnica) => dataEnsayoS.tipoEnsa === tecnica.tipo_ensayo_id);
    },
  },
  created() {
    console.log(this.form_data);
    // console.log(['crea',
    // this.usertxainfo.bitauth_assoc.group_id, this.data_ensayo.laboratoriosList]);
    // si es Admin filtrar solo los externos en laboratorios
    if (this.usertxainfo.bitauth_assoc.group_id === 1) {
      const filterLab = this.data_ensayo.laboratoriosList.filter(
        (lab) => lab.laboratorio.private === 0,
      );
      this.data_ensayo.laboratoriosList = filterLab;
    } else {
      const filterLab = this.data_ensayo.laboratoriosList.filter(
        (lab) => lab.laboratorio.private === 1,
      );
      this.data_ensayo.laboratoriosList = filterLab;
    }
    // console.log(this.form_data);
    // console.log(!!this.form_data.data_ensayo.parametrosid);
    // if(!!this.form_data.data_ensayo.parametrosid){
    //   console.log(!!this.form_data.data_ensayo.parametrosid);
    //   (this.form_data.data_ensayo.parametrosid.length == 0) ? this.form_data.data_ensayo.parametrosid = null : this.form_data.data_ensayo.parametrosid;
    // }
    
    this.$emit('myEvent');
  },
  methods: {
    ...mapMutations('Ensayos', ['setValidDataEnsayo', 'setFormStep', 'setStep', 'clearAllDataForm', 'datosEnsayo', 'fillObjectForm', 'clearAllDataForm']),
    validate() {
      return this.$refs.detalle.validate();
    },
    getText(item,select){
      console.log('getTextitem',item);
      // console.log('getTextselect',select.laboratoriosList[0].laboratorio);
      let selecttext =  item.laboratorio.laboratorio;
      switch (selecttext) {
        case "INTERNO":
          return `${this.getlang(item.laboratorio.laboratorio)}`;
        break;
        default:
             return `${item.laboratorio.laboratorio}`;
          break;
      }
       
    },
    getlang(text){
      console.log(text)
      switch (text) {
        case "INTERNO":
          return text = i18n.t('analisis.interno');
        break;
        default:
             return text;
          break;
      }

    },
    tiempoEnrequecimiento(v) {
      const w = this.form_data.data_ensayo.tipoEnsa === 2 || v;
      return !!w || 'Teimpo de enrequecimiento es requerido ';
    },
    numeroPlacas(v) {
      const w = this.form_data.data_ensayo.tipoEnsa !== 1 || v;
      return !!w || 'Número de placas es requerido ';
    },
    getlangRes(items) {
      // console.log('items', this.data_ensayo);
      items.forEach((element) => {
        // console.log(element);
        const name = element.nombre.replace(/\s+/g, '');
        // console.log('name', name);
        const trad = `${i18n.t(`tipoResul.${name}`)}`;
        // console.log('trad', trad);
        /* eslint-disable no-param-reassign */
        element.nombre2 = trad;
      });
      return items;
    },
    activematriz(ev, item) {
      this.form_data.matrices_unidades.forEach((matriz, key) => {
        if (matriz.id === item.id) {
          if (ev === true) {
            // eslint-disable-next-line
            this.form_data.matrices_unidades[key].activo = true;
          } else {
            // eslint-disable-next-line
            this.form_data.matrices_unidades[key].activo = false;
          }
        }
        return true;
      });
    },
    modalMix() {
      this.$refs.modalmixes.openmodalmix();
    },
    clearFirstStep() {
      this.form_data.data_ensayo.tipoEnsa = null;
      this.form_data.data_ensayo.tipoMeto = null;
      this.form_data.data_ensayo.sitioAnalisis = null;
    },
    clearDataForm() {
      this.form_data.isComercial = false;
      this.form_data.editable = false;
      this.form_data.data_ensayo.empresa = null;
      this.form_data.data_ensayo.ens_tipo = null;
      this.form_data.data_ensayo.descripcion = null;
      this.form_data.data_ensayo.metodologia = null;
      this.form_data.data_ensayo.numplacas = null;
      this.form_data.data_ensayo.parametrosid = [];
      this.form_data.data_ensayo.tiempoenriquecimiento = null;
      // this.form_data.data_ensayo.nombre = null;
      this.form_data.data_ensayo.tiemporesult = null;
      this.form_data.data_ensayo.tecnica = null;
      this.form_data.data_ensayo.productoid = null;
      // this.form_data.data_ensayo.tipoRes = null;
      this.form_data.data_ensayo_original = null;
      this.form_data.data_ensayo.ensayoSelect = '';
      this.form_data.isNew = true;
      this.form_data.data_ensayo.mixes = null;
      this.form_data.nuevoensayo = false;

      const umbral = [{ id: 0, umbral: '', rsa_id: [] }];

      this.form_data.ambientesSelect = umbral;
      this.form_data.aguasSelect = umbral;
      this.form_data.manipuldoresSelect = umbral;
      this.form_data.superficiesSelect = umbral;
      this.form_data.alimentosSelect = umbral;

      this.form_data.matrices_unidades.map((currentValue, index) => {
        const curr = currentValue;
        this.form_data.matrices_unidades[index].activo = false;
        this.form_data.matrices_unidades[index].unidades = '';
        return curr;
      });
    },
    async testSelected(id) {
      this.clearFirstStep();
      this.clearDataForm();
      const testToEdit = await Service.apiToken('POST', 'get-test-id', this.$ls.storage.token, { grupo_ensayo_id: id });
      this.form_data.isNew = true;

      if (testToEdit && testToEdit.data) {
        const dataTest = testToEdit.data[0];
        const empresaProducto = dataTest.ensayos_empresas_productos[0] || [];
        const laboratorio = dataTest.ensayos_laboratorios[0] || [];
        const mix = dataTest.ensayos_has_mixs[0] || [];
        const codesPat = dataTest.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.cod_pat);
        // si el ensayo no es del cliente logueado
        if (this.userInfo.user.id_planta !== dataTest.cliente_id) {
          // cambiamos a true para bloquear campos
          this.form_data.isComercial = true;
          this.form_data.editable = true; // se bloquea si es verdadero
        }
        // laboratorios
        dataTest.ensayos_laboratorios.forEach((element) => {
          this.form_data.laboratorios.push(element.laboratorio_id);
        });
        this.form_data.data_ensayo.tipoEnsa = dataTest.tipo_ensayo_id;
        this.form_data.data_ensayo.tipoMeto = (dataTest.comercial) ? 1 : 0;
        this.form_data.data_ensayo.empresa = empresaProducto.empresa_id || null;
        // this.form_data.data_ensayo.nombre = dataTest.ensa_informe;
        this.form_data.data_ensayo.nombre = dataTest.ensayos_easypcrs[0].name_ensayo;
        this.form_data.data_ensayo.ens_tipo = dataTest.d_tipo_de_ensayo;
        // this.form_data.data_ensayo.descripcion = dataTest.ensa_desc;
        this.form_data.data_ensayo.descripcion = dataTest.ensayos_easypcrs[0].descripcion;
        this.form_data.data_ensayo.metodologia = dataTest.metodologia;
        this.form_data.data_ensayo.numplacas = dataTest.numero_placas;
        this.form_data.data_ensayo.parametrosid = codesPat;
        this.form_data.data_ensayo.tiempoenriquecimiento = dataTest.tiempo_incubacion;
        this.form_data.data_ensayo.tiemporesult = dataTest.dias;
        this.form_data.data_ensayo.tecnica = dataTest.tecnicas_id;
        this.form_data.data_ensayo.productoid = empresaProducto.producto_empresa_id || null;
        this.form_data.data_ensayo.tipoRes = dataTest.t_resultado;
        this.form_data.data_ensayo.sitioAnalisis = laboratorio.laboratorio_id || null;
        this.form_data.data_ensayo_original = dataTest.grupo_ensayo_id;
        this.form_data.data_ensayo.mixes = mix.mixs_id || null;
        this.form_data.nuevoensayo = true;
        this.form_data.clasificacion = dataTest.clasificacion;
        this.form_data.steriplex = (dataTest.steriplex === 1);
        this.form_data.kitsteriplex = dataTest.kit_steriplex;

        testToEdit.data.forEach((test) => {
          const matrixObject = this.form_data.matrices_unidades;
          const matrixFound = matrixObject.findIndex((element) => element.id === test.matrices_id);
          this.form_data.matrices_unidades[matrixFound].activo = true;
          this.form_data.matrices_unidades[matrixFound].unidades = test.unidades;

          const groups = _.groupBy(test.umbrales, 'umbral');
          let umbral = [];
          const arrayMatriz = [12, 9, 7];
          Object.keys(groups).map((group, index) => {
            // if (groups[group][0].matriz_id === 12) {
            if (arrayMatriz.includes(groups[group][0].matriz_id)) {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                unidades: groups[group][0].unidades,
                rsa_id: groups[group].map((umb) => (umb.subrsa_id === 0 ? `${umb.rsa_id}_R` : `${umb.subrsa_id}_S_${umb.rsa_id}`)),
              });
            } else {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                rsa_id: groups[group].map((umb) => umb.rsa_id),
                unidades: groups[group][0].unidades,
              });
            }
            return true;
          });

          const noUmbral = [{ id: 0, umbral: '', rsa_id: [] }];
          umbral = (umbral.length > 0) ? umbral : noUmbral;

          switch (test.matrices_id) {
            case 6: this.form_data.data_umbrales.ambientesSelect = umbral; break;
            case 7: this.form_data.data_umbrales.aguasSelect = umbral; break;
            case 8: this.form_data.data_umbrales.manipuldoresSelect = umbral; break;
            case 9: this.form_data.data_umbrales.superficiesSelect = umbral; break;
            case 12: this.form_data.data_umbrales.alimentosSelect = umbral; break;
            default: break;
          }
          return true;
        });
      }
    },
    async agregaEmpresa() {
      // si search es diferente de null vamos a enviar a guardarlo
      if (this.search != null) {
        const crearE = {
          nombre: this.search,
          cliente_id: 1,
        };
        const creaEmpresa = await Service.apiToken('POST', 'crea-empresa', this.$ls.storage.token, crearE);
        // hacemos push en el objeto que se esta terando
        this.data_ensayo.empresasList.push(creaEmpresa.data);
      }
    },
    async agregaProducto() {
      if (this.searchProd != null) {
        const crearProd = {
          nombre: this.searchProd,
          cliente_id: 1,
          empresa_id: this.form_data.data_ensayo.empresa,
        };
        const creaproducto = await Service.apiToken('POST', 'crea-producto', this.$ls.storage.token, crearProd);
        this.data_ensayo.productosEmpresaslist.push(creaproducto.data);
      }
    },
    async agregaParametro() {
      if (this.searchParam != null) {
        const dateact = new Date();
        const seconds = dateact.getTime();
        const crearParam = {
          nombre: this.searchParam,
          nomcort: `${this.searchParam}_${seconds}`,
          clasificacion: 2,
          especie: null,
          unidad: null,
          color: '#795548',
          nombre_es: this.searchParam,
        };
        const creaparam = await Service.apiToken('POST', 'crea-parametro', this.$ls.storage.token, crearParam);
        this.data_ensayo.parametros.push(creaparam.data);
      }
    },
    openmodal() {
      this.$refs.modalparametros.openmodal();
      
    },
    openmodalParametros() {
      // console.log('hola');
      // console.log('create', this.usertxainfo);
      this.$refs.modalparametros.openmodal();
    },
    openmodalConfigRecuento() {
      console.log('hola');
      // console.log('create', this.usertxainfo);
      this.$refs.modalConfigRecuento.openmodal();
    },
    openmodalTecnicas() {
      this.$refs.modalCondicionParametros.openmodal();
    },
    disabled() {
        console.log("parametrosid",this.form_data.data_ensayo.tipoRes)
        console.log("microbiologia",this.form_data.data_ensayo.microbiologia)
        console.log("editable",this.form_data.editable)
      let status = false;
      if( this.form_data.data_ensayo.microbiologia !== 1 ){
        status = true;
      }
      if ( this.form_data.data_ensayo.parametrosid.length == 0 ) {
        status = true;
      }
        console.log({status})
      return status;
      /* 
      if (this.form_data.editable === true) {
        return true;
      }
      return this.form_data.data_ensayo.microbiologia !== 1;
       */
    },
  },
};
</script>
